
import {
    computed,
    defineComponent,
    PropType,
    ref,
    reactive,
    unref,
    watch,
} from 'vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import TableWrapper from '@/views/Payment/components/TableWrapper.vue';
import { context } from 'ant-design-vue/lib/vc-image/src/PreviewGroup';
type Key = ColumnProps['key'];
export default defineComponent({
    name: 'App',
    props: {
        entity: {
            type: Array as PropType<string[]>,
        },
        column: {
            type: Array,
        },
        data: {
            type: Object,
        },
    },
    setup(props, context) {
        const activeKey = ref();
        watch(
            () => props.entity,
            (val) => {
                if (val) {
                    activeKey.value = val[0];
                }
            }
        );
        const tableData = ref([]);
        const tabs = computed(() => {
            const obj = {};
            if (props.entity && props.data) {
                props.entity.forEach((key) => {
                    const data = props.data?.[key] ? props.data[key] : [];
                    obj[key] = {
                        key,
                        desc: `${key}(${data.length})`,
                        data,
                    };
                });
            }
            return obj;
        });
        // 复选框
        const tableSelection = reactive<{
            selectedRowKeys: Key[];
            onChange: Function;
        }>({
            onChange: (selectedRowKeys: string[], selectRows: any[]) => {
                tableSelection.selectedRowKeys = selectedRowKeys;
                context.emit('on-select-change', selectedRowKeys, selectRows);
            },
            selectedRowKeys: [],
        });
        // 切换tab
        const handleTabChange = () => {
            tableSelection.selectedRowKeys = [];
        };
        const handleSetClick = (record: any) => {
            context.emit('on-set', record.id as string, record);
        };
        const handleBatchSetClick = () => {
            context.emit('on-batch-set');
        };
        return {
            tableData,
            tabs,
            activeKey,
            tableSelection,
            handleTabChange,
            handleSetClick,
            handleBatchSetClick,
        };
    },
    components: {
        TableWrapper,
    },
});
