
import {
    defineComponent,
    toRefs,
    ref,
    watch,
    computed,
    unref,
    onUnmounted,
} from 'vue';
import moment from 'moment';
import { useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import useEventbus from '@/hooks/checking/spotChecking/useEventbus';
import useCheckingPreparation from '@/hooks/checking/spotChecking/checkingPreparation/useCheckingPreparation';
import TabTable from './tabTable.vue';
import SetModal from './setModal.vue';
import DownloadModal from './downloadModal.vue';
import { useStore } from 'vuex';
import useThousands from '@/utils/payment/useThousands';
import BuSelector from '@/views/Payment/components/BuSelector.vue';
// import EntitySelector from "@/views/Payment/components/EntitySelector.vue";
import AllEntitySelector from '@/views/Payment/components/AllEntitySelector.vue';
export default defineComponent({
    name: 'App',
    props: {},
    setup() {
        const router = useRouter();
        const { customEmit, customOff } = useEventbus();
        const { commit } = useStore();
        const dealerColumn = ref([
            {
                title: 'Package Code',
                dataIndex: 'packageCode',
                width: 200,
                slots: {
                    customRender: 'link',
                },
            },
            {
                title: 'Program No.',
                dataIndex: 'programNo',
                width: 100,
                customRender: useThousands(),
                align: 'right',
            },
            {
                title: 'Claim No.',
                dataIndex: 'claimNo',
                width: 80,
                customRender: useThousands(),
                align: 'right',
            },
            {
                title: 'Operation',
                slots: { customRender: 'operation' },
                align: 'center',
                width: 100,
            },
        ]);
        const flColumn = ref([
            {
                title: 'Payout Code',
                dataIndex: 'payoutCode',
                width: 245,
                slots: {
                    customRender: 'link',
                },
            },
            {
                title: 'Vin No.',
                dataIndex: 'vinNo',
                width: 80,
                customRender: useThousands(),
                align: 'right',
            },
            {
                title: 'Submission Date',
                dataIndex: 'submissionDate',
                width: 120,
            },
            {
                title: 'Operation',
                slots: { customRender: 'operation' },
                align: 'center',
                width: 90,
            },
        ]);
        const handleLinkClick = (record: any, type: string) => {
            if (type === 'Dealer') {
                commit('spotChecking/updatePackageData', record);
                router.push({
                    path: '/spotChecking/packageDetail',
                });
            }
            if (type === 'Fl') {
                commit('spotChecking/updatePackageFinanceData', record);
                router.push({
                    path: '/spotChecking/packageFinanceDetail',
                });
            }
        };
        const {
            query,
            userList,
            getUserList,
            getEntityList,
            entityList,
            getDealerList,
            dealerList,
            getFlList,
            flList,
            getBuList,
            buList,
            totalCount,
        } = useCheckingPreparation();
        watch(totalCount, () => {
            customEmit('on-count-change', totalCount.value);
        });
        const getCurrentDate = () => {
            const startDate = moment()
                .month(moment().month())
                .startOf('month')
                .format('YYYY-MM-DD');

            const endDate = moment()
                .month(moment().month())
                .endOf('month')
                .format('YYYY-MM-DD');
            query.submissionDate = [startDate, endDate];
        };
        const search = () => {
            commit('updatePageSpinning', true);
            const dealer = getDealerList();
            const fl = getFlList();
            Promise.all([dealer, fl])
                .then(() => {
                    commit('updatePageSpinning', false);
                })
                .catch((error) => {
                    console.log(error, 1111);
                    commit('updatePageSpinning', false);
                });
        };
        const handleSetChange = () => {
            search();
        };
        const reset = () => {
            getCurrentDate();
            query.entity = [];
            query.bu = [];
            search();
        };
        const init = () => {
            getCurrentDate();
            getUserList();
            getEntityList();
            getBuList();
            search();
        };
        init();

        const dealerKeys = ref<string[]>([]);
        const packageCodes = ref<string[]>([]);
        const flKeys = ref<string[]>([]);
        const dealerTableKeys = ref<string[]>([]);
        const flTableKeys = ref<string[]>([]);
        const selectedKeysLength = computed(() => {
            return dealerTableKeys.value.length + flTableKeys.value.length;
        });
        const handleTableSelectChange = (keys: string[], rows: any[], type: string) => {
            if (type === 'Dealer') {
                dealerTableKeys.value = unref(keys);
                packageCodes.value = rows.map(item => item.packageCode)
                console.log(packageCodes.value)
            } else {
                flTableKeys.value = unref(keys);
                packageCodes.value = [];
            }
        };
        const modalVisible = ref<boolean>(false);
        const handleModalShow = () => {
            if (selectedKeysLength.value) {
                dealerKeys.value = [...dealerTableKeys.value];
                flKeys.value = [...flTableKeys.value];
                modalVisible.value = true;
            } else {
                message.warn('Please select the data first');
            }
        };
        const handleBatchSet = (type: string) => {
            if (type === 'Dealer' && dealerTableKeys.value.length) {
                dealerKeys.value = [...dealerTableKeys.value];
                modalVisible.value = true;
            } else if (type === 'Fl' && flTableKeys.value.length) {
                flKeys.value = [...flTableKeys.value];
                modalVisible.value = true;
            } else {
                message.warn('Please select the data first');
            }
        };
        const handleSet = (id: string, row: any, type: string) => {
            if (type === 'Dealer') {
                dealerKeys.value = [id];
                packageCodes.value = [row.packageCode]
            } else {
                flKeys.value = [id];
                packageCodes.value = [];
            }
            modalVisible.value = true;
        };
        const downloadModalVisible = ref(false);
        const handleDownload = () => {
            downloadModalVisible.value = true;
        };
        return {
            ...toRefs(query),
            userList,
            entityList,
            buList,
            dealerList,
            flList,
            dealerColumn,
            flColumn,
            search,
            reset,
            handleModalShow,
            modalVisible,
            handleLinkClick,
            handleTableSelectChange,
            handleSet,
            selectedKeysLength,
            dealerKeys,
            packageCodes,
            flKeys,
            handleSetChange,
            handleDownload,
            downloadModalVisible,
            handleBatchSet,
        };
    },
    components: {
        TabTable,
        SetModal,
        DownloadModal,
        BuSelector,
        // EntitySelector,
        AllEntitySelector,
    },
});
