import { ref, reactive, computed, onMounted, toRaw } from "vue";
import moment from "moment";
import { useStore } from 'vuex';
import {
  fetchEntityList,
  fetchBuList,
  fetchUserList,
  // fetchDealerList,
} from "@/views/Checking/spotChecking/spotCheckingVonder/checkingPreparation/API";
import { fetchFlList, fetchDealerList } from "@/API/checking/spotChecking";
import { getNomalDelegationList } from "@/API/approvalDelegation"
const useCheckingPreparation = () => {
  const query = reactive<{
    entity: string[];
    bu: string[];
    submissionDate: string[];
  }>({
    entity: [],
    bu: [],
    submissionDate: [],
  });
  // user列表

  const userRes = ref();
  const userList = computed(() => {
    return userRes.value;
  });
  const getUserList = () => {
    getNomalDelegationList({
      legalEntity: {
        entityNameEn: 'BMBS'
      },
      organization: {
        entityNameEn: 'Controlling Vendor'
      }
    }).then((res: any) => {
      userRes.value = res;
    });
  };
  // entity 列表
  const entityRes = ref();
  const entityList = computed(() => {
    return entityRes.value?.data.data;
  });
  const getEntityList = () => {
    fetchEntityList().then((res: any) => {
      entityRes.value = res;
    });
  };
  // Bu列表
  const buRes = ref();
  const buList = computed(() => {
    return buRes.value?.data.data;
  });
  const getBuList = () => {
    fetchBuList().then((res: any) => {
      buRes.value = res;
    });
  };
  // delear/金融 列表
  const _entitylist = [
    {
      entityName: "BBAC",
    },
    {
      entityName: "FBAC",
    },
    {
      entityName: "MBCL",
    },
    {
      entityName: "DENZA",
    },
  ];
  // const _bulist = [
  //   { nameEn: "MB" },
  //   { nameEn: "MB East" },
  //   { nameEn: "MB West" },
  //   { nameEn: "MB North" },
  //   { nameEn: "MB South" },
  //   { nameEn: "MB G Class" },
  //   { nameEn: "EQ" },
  //   { nameEn: "AMG" },
  //   { nameEn: "CPO" },
  //   { nameEn: "MB CPO" },
  //   { nameEn: "AMG CPO" },
  //   { nameEn: "Company car" },
  //   { nameEn: "VAN" },
  //   { nameEn: "VAN Fleet" },
  //   { nameEn: "Fleet" },
  //   { nameEn: "DENZA" },
  //   { nameEn: "DENZA Fleet" },
  // ];
  const { state } = useStore();
  const _bulist = state.user.buList
  // dealer列表
  const dealerRes = ref();
  const dealerList = computed(() => {
    return dealerRes.value ? dealerRes.value : [];
  });
  const getDealerList = () => {
    const bu = query.bu.length === 0 ? _bulist.map((item: any) => item.nameEn) : query.bu;
    const entity = query.entity.length === 0 ? _entitylist.map((item: any) => item.entityName) : query.entity;
    return new Promise((resolve, reject) => {
      fetchDealerList({
        buList: bu,
        entityList: entity,
        startDate: moment(query.submissionDate[0]).format("YYYY-MM-DD"),
        endDate: moment(query.submissionDate[1]).format("YYYY-MM-DD"),
      }).then((res: any) => {
        dealerRes.value = res;
        resolve(res)
      }).catch((error) => {
        reject(error)
      })
    })
    
  };
  // FL 列表
  const flRes = ref();
  const flList = computed(() => {
    return flRes.value ? flRes.value : [];
  });
  const getFlList = () => {
    const bu = query.bu.length === 0 ? state.user.buList?.map((bu: {nameEn: string}) => bu.nameEn) : query.bu;
    const entity = query.entity.length === 0 ? _entitylist?.map((entity: {entityName: string}) => entity.entityName) : query.entity;
    return new Promise((resolve, reject) => {
      fetchFlList({
        buList: bu,
        entityList: entity,
        startDate: moment(query.submissionDate[0]).format("YYYY-MM-DD"),
        endDate: moment(query.submissionDate[1]).format("YYYY-MM-DD"),
      }).then((res: any) => {
        flRes.value = res;
        resolve(res)
      }).catch((error) => {
        reject(error)
      })
    })
  };
  const totalCount = computed(() => {
    let total = 0;
    for (const key in dealerList.value) {
      total += dealerList.value[key].length;
    }
    for (const key in flList.value) {
      total += flList.value[key].length;
    }
    return total;
  });
  return {
    query,
    userList,
    getUserList,
    getEntityList,
    entityList,
    getDealerList,
    dealerList,
    getFlList,
    flList,
    getBuList,
    buList,
    totalCount,
  };
};
export default useCheckingPreparation;
