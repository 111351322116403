
import { defineComponent, reactive, ref, watch } from "vue";
import { message } from "ant-design-vue";
import useModel from "@/hooks/useModel";
import { Moment } from "moment";
import { context } from "ant-design-vue/lib/vc-image/src/PreviewGroup";
import { setTimeLine } from "@/API/checking/spotChecking";
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dealerIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    packageCodes: {
      type: Array,
      default: () => {
        return [];
      },
    },
    flIds: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  setup(props, context) {
    const _visible = useModel(props, "visible");
    const formState = reactive({
      priority: 2,
      assignJob: "",
      timeLine: ref([]),
    });
    const rules = reactive({
      priority: [
        {
          required: true,
          type: 'number',
          message: "Please select priority",
          trigger: "change",
        },
      ],
      assignJob: [
        {
          required: true,
          message: "Please select assign job",
          trigger: "change",
        },
      ],
      timeLine: [
        {
          required: true,
          message: "Please set Time Line",
          trigger: "change",
          type: "array",
        },
      ],
    });
    const handleCancel = () => {
      _visible.value = false;
    };
    const formRef = ref();
    const handleConfirm = () => {
      formRef.value
        .validate()
        .then(() => {
          setTimeLine({
            assignJob: (props.user.find((item: any) => item.usrid === formState.assignJob) as any).fulnm,
            assignJobUserId: formState.assignJob,
            dealerIdList: props.dealerIds,
            packageCodeList: props.packageCodes,
            financeIdList: props.flIds,
            priority: formState.priority,
            timeLineBegin: formState.timeLine[0],
            timeLineEnd: formState.timeLine[1]
          }).then(() => {
            context.emit('on-set-change')
            message.success('Set success')
            _visible.value = false;
          });
        })
        .catch((error: any) => {
          console.log(error);
        });
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
     // 关闭弹窗清空表单
    watch(_visible, (val) => {
      if (!val) {
        resetForm();
      }
    });
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      _visible,
      formState,
      rules,
      formRef,
      handleCancel,
      handleConfirm,
    };
  },
});
