import { fetchGet, fetchDelete, fetchPost, fetchGetMess, fetchPostPic, fetchDeletePic} from './index';

//#region 6041 6042 Approval Delegation
export const getDelegationList = (): Promise<any> => {
    return fetchGet('/apapi/delegationSetting/getDelegationList')
}
export const getDefaultDelegationList = (): Promise<any> => {
    return fetchGet('/apapi/delegationSetting/getDefaultDelegationList')
}
export const saveDelegationSetting = (params = {}): Promise<any> => {
    return fetchPostPic('/apapi/delegationSetting/save', params)
}
export const deleteDelegation = (params = {}): Promise<any> => {
    return fetchDeletePic('/apapi/delegationSetting/delete', { params })
}
export const getNomalDelegationList = (params = {}): Promise<any> => {
    return fetchPost('/usapi/noAuth/users', params)
}
export const findByUsrnmWithoutLoginUser = (usernm = ''): Promise<any> => {
  return fetchGet('/usapi/users/findByUsrnmWithoutLoginUser', { usernm })
}
//#endregion