const Data: string[] = [
  "MB",
  "MB East",
  "MB West",
  "MB North",
  "MB South",
  "MB G Class",
  "EQ",
  "AMG",
  "CPO",
  "MB CPO",
  "AMG CPO",
  "Company car",
  "VAN",
  "VAN Fleet",
  "Fleet",
  "DENZA",
  "DENZA Fleet",
];
export default Data;
