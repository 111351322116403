import { toRaw } from 'vue'
const useMock = <T>(data: T[] | T, time = 2000 ,query?: Record<string, unknown>) => {
  console.log('query: ', toRaw(query));
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: {
          data,
          message: "OK",
        }
      })
      console.log("load success!!!")   
    }, time)
  })
}
export default useMock
