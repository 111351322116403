
import { defineComponent, computed, ref, reactive } from "vue";
import { message } from "ant-design-vue";
import useModel from "@/hooks/useModel";
import downloadFile from "@/utils/claim/downloadFile";
import { ColumnProps } from "ant-design-vue/es/table/interface";
import { fetchRoundList, checkingroundById } from "@/API/checking/spotChecking";
type Key = ColumnProps["key"];
export default defineComponent({
  name: "App",
  props: {},
  setup(props, context) {
    const _visible = useModel(props, "visible");
    const column = ref([
      {
        title: "Round Name",
        dataIndex: "roundName",
      },
    ]);
    const roundRes = ref();
    const data = computed(() => {
      return roundRes.value;
    });
    fetchRoundList().then((res) => {
      roundRes.value = res;
    });
    const tableSelection = reactive<{
      selectedRowKeys: Key[];
      onChange: Function;
      type: string;
    }>({
      onChange: (selectedRowKeys: string[]) => {
        tableSelection.selectedRowKeys = selectedRowKeys;
        context.emit("on-select-change", selectedRowKeys);
      },
      selectedRowKeys: [],
      type: 'radio'
    });
    const handleDownload = () => {
      const id = tableSelection.selectedRowKeys[0] as string
      const url = `/claimapi/checking/plan/export/detail/by/checkingroundid?checkingRoundId=${id}`;
      const params = {
        url,
        method: "get",
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8");
    }
    const handleCancel = () => {
      _visible.value = false
    }
    return {
      tableSelection,
      column,
      data,
      handleDownload,
      handleCancel
    };
  },
});
