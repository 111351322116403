import useMock from "@/utils/payment/useMock"
import EntityData from './mockEntity'
import BuData from './mockBuList'
import DealerData from './mockDealerList'
import UserData from './mockUserList'
import { DataDealerProps } from "./type";

export const fetchEntityList = (query?: any) => {
    return new Promise((resolve, reject) => {
        useMock<string>(EntityData, 300, query).then((res) => {
            resolve(res)
        });
    })
}
export const fetchUserList = (query?: any) => {
    return new Promise((resolve, reject) => {
        useMock<string>(UserData, 300, query).then((res) => {
            resolve(res)
        });
    })
}
export const fetchBuList = (query?: any) => {
    return new Promise((resolve, reject) => {
        useMock<string>(BuData, 300, query).then((res) => {
            resolve(res)
        });
    })
}

export const fetchDealerList = (query?: any) => {
    return new Promise((resolve, reject) => {
        useMock<Record<string, DataDealerProps[]>>(DealerData, 300, query).then((res) => {
            resolve(res)
        });
    })
    
}